<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>冻品申报管理</el-breadcrumb-item>
        <el-breadcrumb-item>冻品申报列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt0">
      <div class="pt20"  style="text-align:left;">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="检验证号"
              v-model="page.quarantine_cert_number"
              clearable
              @clear="onSubmit(page.warehouse_name)"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              placeholder="产品名称"
              v-model="page.product_name"
              clearable
              @clear="onSubmit(page.warehouse_name)"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" size="medium"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-upload
              class="upload"
              :multiple="false"
              action="#"
              ref="upload"
              accept=".xlsx"
              :limit="1"
              :show-file-list="false"
              :before-upload="beforeUpload"
              style="float: right"
            >
              <el-button type="success" size="medium" @click="batchAdd"
                >批量导入</el-button
              >
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button size="medium" type="warning" @click="downloadInfo(1)"
              >下载模板</el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <!-- <el-row class="box search mt20">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="检验证号"
                v-model="page.quarantine_cert_number"
                clearable
                class="input-with-select"
                @clear="onSubmit(page.warehouse_name)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                      placeholder="产品名称"
                      v-model="page.product_name"
                      clearable
                      class="input-with-select"
                      @clear="onSubmit(page.warehouse_name)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="tl mr10">
            <el-form-item size="medium">
              <el-button
                type="primary"
                @click="onSubmit"
                class="general_bgc general_border cfff"
                >查询</el-button
              >
            </el-form-item>
          </el-col>
          <el-col :span="2" class="tl mr10">
            <el-form-item>
              <el-upload
                      class="upload"
                      :multiple="false"
                      action="#"
                      ref="upload"
                      accept=".xlsx"
                      :limit="1"
                      :show-file-list="false"
                      :before-upload="beforeUpload"
                      style="float: right">
                <el-button type="success" size="medium" @click="batchAdd"
                >批量导入</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item>
              <el-button size="medium" type="warning" @click="downloadInfo(1)">下载模板</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row> -->
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%" id="out-table">
          <el-table-column
            fixed
            align="center"
            width="100"
            ref="multipleTable"
            type="index"
            label="序号"
          >
          </el-table-column>

          <el-table-column
            prop="quarantine_cert_number"
            min-width="120"
            :show-overflow-tooltip="true"
            label="检验证号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="product_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="产品名称"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="product_category_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="产品分类"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="declared_weight"
            min-width="100"
            :show-overflow-tooltip="true"
            label="报检重量"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="origin_country_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="原厂国"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="quarantine_cert_url"
            min-width="100"
            :show-overflow-tooltip="true"
            label="检验证明"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="handlePictureCardPreview(scope.row.quarantine_cert_url)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="ncovid_reports_urls"
            min-width="120"
            :show-overflow-tooltip="true"
            label="核酸检测报告"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="handlePictureCardPreview(scope.row.ncovid_reports_urls)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="disinfection_cert_urls"
            min-width="160"
            :show-overflow-tooltip="true"
            label="消毒证明"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="
                  handlePictureCardPreview(scope.row.disinfection_cert_urls)
                "
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="warehouse_cert_urls"
            min-width="160"
            :show-overflow-tooltip="true"
            label="出仓证明"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib orange"
                size="mini"
                @click="handlePictureCardPreview(scope.row.warehouse_cert_urls)"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="entry_port_name"
            min-width="160"
            :show-overflow-tooltip="true"
            label="入境口岸名称"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="entry_date"
            min-width="160"
            :show-overflow-tooltip="true"
            label="入境日期"
            align="center"
          >
          </el-table-column>
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog :visible.sync="showViewer">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
      <el-dialog :visible.sync="showResult" height="500px">
        <h2>
          该Excel中检验证编号不符合规范的信息有<span style="color: red">{{
            irregular.length
          }}</span
          >条
        </h2>
        <el-table :data="irregular">
          <el-table-column
            prop="quarantine_cert_number"
            min-width="120"
            :show-overflow-tooltip="true"
            label="检验证号"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="product_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="商品名"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="gtin"
            min-width="80"
            :show-overflow-tooltip="true"
            label="品码"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="product_category_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="商品类型"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="origin_country_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="原厂国"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="entry_port_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="入境口岸"
            align="center"
          >
          </el-table-column>
        </el-table>
        <h2>
          该Excel中商品不存在的信息有<span style="color: darkblue">{{
            goodsExist.length
          }}</span
          >条
        </h2>
        <el-table :data="goodsExist">
          <el-table-column
            prop="quarantine_cert_number"
            min-width="120"
            :show-overflow-tooltip="true"
            label="检验证号"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="product_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="商品名"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="gtin"
            min-width="80"
            :show-overflow-tooltip="true"
            label="品码"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="product_category_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="商品类型"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="origin_country_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="原厂国"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="entry_port_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="入境口岸"
            align="center"
          >
          </el-table-column>
        </el-table>
        <h2>
          该Excel中重复的申报记录信息有<span style="color: crimson">{{
            repeat.length
          }}</span
          >条
        </h2>
        <el-table :data="repeat">
          <el-table-column
            prop="quarantine_cert_number"
            min-width="120"
            :show-overflow-tooltip="true"
            label="检验证号"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="product_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="商品名"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="gtin"
            min-width="80"
            :show-overflow-tooltip="true"
            label="品码"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="product_category_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="商品类型"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="origin_country_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="原厂国"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="entry_port_name"
            min-width="80"
            :show-overflow-tooltip="true"
            label="入境口岸"
            align="center"
          >
          </el-table-column>
        </el-table>
      </el-dialog>
    </el-main>
  </el-container>
</template>
<script>
import axios from "@/util/api";
import { local } from "@/util/util";
import { mapState } from "vuex";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  components: {},
  data() {
    return {
      files: "",
      user: {},
      page: {
        supply_name: "", //供销单位的名称
        concat: "", //联系人
        pager_offset: "0",
        pager_openset: "10",
      },
      timer: "",
      loading: false,
      currentPage: 1,
      total: 0,
      tableData: [],
      goodsExist: [],
      irregular: [],
      repeat: [],
      activeName: "1",
      company_id: "",
      url: "",
      uploadVis: false,
      showViewer: false,
      showResult: false,
      dialogImageUrl: "",
    };
  },
  filters: {},
  computed: {
    ...mapState({
      carState: (state) => state.carManage.carState,
    }),
  },
  mounted() {
    this.page = Object.assign({}, this.page);
    this.user = local.get("user");
    this.page.company_id = String(local.get("company_id"));
    this.page.user_id = String(this.user.user_id);
    this.currentPage = this.page.pager_offset / 10 + 1;
    this.init(this.page);
  },
  methods: {
    beforeUpload(file) {
      // console.log(file,'文件');
      this.files = file;
      const extension2 = file.name.split(".")[1] === "xlsx";
      const isLt1000M = file.size / 1024 / 1024 < 1000;
      if (!extension2) {
        this.$message.warning("上传模板只能是 xlsx格式!");
        return;
      }
      if (!isLt1000M) {
        this.$message.warning("上传模板大小不能超过 1000MB!");
        return;
      }
      this.fileName = file.name;
      setTimeout(() => {
        this.submitUpload();
      }, 500);
      return false; // 返回false不会自动上传
    },
    success() {
      this.$message.success("上传成功");
    },
    // 上传excel
    submitUpload() {
      //console.log('上传'+this.files.name)
      if (this.fileName == "") {
        this.$message.warning("请选择要上传的文件！");
        return false;
      }
      let fileFormData = new FormData();

      fileFormData.append("code", "t_pathology_info_excel");
      fileFormData.append("description", "冻品申报批量上传");
      fileFormData.append("file", this.files, this.fileName);
      //filename是键，file是值，就是要传的文件，test是要传的文件名
      fileFormData.append("company_id", this.page.company_id);
      fileFormData.append("user_id", this.page.user_id);
      // 执行上传excel
      let id = "";
      axios
        .uploadFile("/pc/importedColdChainGoods/importExcel", fileFormData)
        .then((resp) => {
          if (resp.code != 200) {
            this.$message.error("excel上传失败，请重新上传");
          }
          if (
            resp.data.goodsNotExist.length > 0 ||
            resp.data.irregular.length > 0 ||
            resp.data.repeat.length > 0
          ) {
            this.showResult = true;
            this.goodsExist = resp.data.goodsNotExist;
            this.irregular = resp.data.irregular;
            this.repeat = resp.data.repeat;
          } else {
          }
          this.init(this.page);
        })
        .catch((e) => {
          this.$message.error("excel上传失败，请重新上传");
        })
        .finally(() => {
          if (id) {
            // 触发生成订单实体数据
            this.generateBill(id);
          }
        });
    },
    downloadInfo(param) {
      axios
        .fileget("/pc/download/template", { fileName: "frozenGoods.xlsx" })
        .then((res) => {});
    },
    handlePictureCardPreview(url) {
      if (url) {
        let pics = JSON.parse(url);
        this.dialogImageUrl = pics[0].url;
        this.showViewer = true;
      } else {
        this.$message.warning("没有上传证件！");
      }
    },
    init(params) {
      axios.get("/pc/importedColdChainGoods/single", params).then((v) => {
        this.tableData = v.data.data_list;
        this.total = v.data.pager_count;
      });
    },
    // 搜索
    onSubmit() {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.init(this.page);
    },
    // 分页
    currentChange(page) {
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10);
      this.init(this.page);
    },
    //批量导入
    batchAdd() {
      this.uploadVis = true;
    },
    //编辑
    goUpdate(row) {
      this.$router.push({
        name: "WarehouseListAdd",
        query: { warehouse_id: row.id, nature: row.nature },
      });
    },
    handleStateChange(val, row) {
      const { id } = row;
      let car_state = val + "";
      axios.put("", { car_id: id + "", car_state }).then((response) => {
        this.init(this.page);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}
</style>
